<template>
  <div>
    <div class="mail-box-header">
      <form method="get" action="index.html" class="float-right mail-search">
        <div class="input-group">
          <input
            type="text"
            class="form-control form-control-sm"
            name="search"
            placeholder="Search email"
          />
          <div class="input-group-btn">
            <button type="submit" class="btn btn-sm btn-primary">Search</button>
          </div>
        </div>
      </form>
      <h2>Inbox (16)</h2>
      <div class="mail-tools tooltip-demo m-t-md">
        <div class="btn-group float-right">
          <button class="btn btn-white btn-sm" @click="prevPage">
            <i class="fa fa-arrow-left"></i>
          </button>
          <button class="btn btn-white btn-sm" @click="nextPage">
            <i class="fa fa-arrow-right"></i>
          </button>
        </div>
        <button
          class="btn btn-white btn-sm"
          data-toggle="tooltip"
          data-placement="left"
          title="Refresh inbox"
          @click.prevent="get"
        >
          <i class="fa fa-refresh"></i>
          Refresh
        </button>
        <button
          class="btn btn-white btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Mark as read"
        >
          <i class="fa fa-eye"></i>
        </button>
        <button
          class="btn btn-white btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Mark as important"
        >
          <i class="fa fa-exclamation"></i>
        </button>
        <button
          class="btn btn-white btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Move to trash"
        >
          <i class="fa fa-trash-o"></i>
        </button>
      </div>
    </div>
    <div class="mail-box">
      <div id="detal-mail-box" class="detal-mail-box">
        <div class="closeIcon" @click="closeDetal()">
          <i class="fa fa-times"></i>
        </div>

        <div class="detal-mail-subject" v-if="detalMail.subject">
          <h5 style="text-align: center;">{{ detalMail.subject }}</h5>
        </div>

        <div class="detal-mail-from" v-if="detalMail.sender">
          <i class="fa fa-user-circle-o"></i>
          <a :href="`mailto:${detalMail.sender.emailAddress.address}`">{{
            detalMail.sender.emailAddress.name
          }}</a>
        </div>
        <div class="detal-mail-to" v-if="detalMail.toRecipients">
          DO:
          <div
            v-for="(recipients, index) in detalMail.toRecipients"
            :key="index"
          >
            <a :href="`mailto:${recipients.emailAddress.address}`">{{
              recipients.emailAddress.name
            }}</a>
          </div>
        </div>
        <div class="detal-mail-dw" v-if="detalMail.ccRecipients">
          <span v-if="detalMail.ccRecipients.length > 0">DW:</span>
          <div
            v-for="(ccRecipients, index) in detalMail.ccRecipients"
            :key="index"
          >
            <a :href="`mailto:${ccRecipients.emailAddress.address}`">
              {{ ccRecipients.emailAddress.address }}</a
            >
          </div>
        </div>
        <div class="detal-mail-date" v-if="detalMail.sentDateTime">
          <div style="display: flex;">
            <i class="fa fa-calendar"></i>
            <p>{{ detalMail.sentDateTime }}</p>
          </div>
        </div>
        <div class="icon-attachment" v-if="detalMail.hasAttachments">
          Załącznik:
          <a href="#">
            <i class="fa fa-paperclip"></i>
          </a>
        </div>
        <hr />

        <div class="detal-mail-content-row">
          <div class="button-detal-mail">
            <a href="#" class="btn" style="margin-right: 10px;"
              >Ważne <i class="fa fa-exclamation" aria-hidden="true"></i
            ></a>
            <a href="#" class="btn" style="margin-right: 10px;"
              >W trakcie <i class="fa fa-spinner" aria-hidden="true"></i
            ></a>
            <a href="#" class="btn" style="margin-right: 10px;"
              >Gotowe <i class="fa fa-check-square-o" aria-hidden="true"></i
            ></a>
            <a href="#" class="btn"
              >Wyślij <i class="fa fa-paper-plane" aria-hidden="true"></i
            ></a>
          </div>

          <div class="detal-mail-value body-box" v-if="detalMail.body">
            <iframe id="myIfrm" />
          </div>
        </div>
      </div>

      <table class="table table-hover table-mail">
        <tbody>
          <tr
            :class="{ unread: !email.isRead }"
            v-for="(email, index) in emails.value"
            @click="emailDetal(email, $event)"
            :key="index"
          >
            <td class="check-mail">
              <div class="icheckbox_square-green" style="position: relative">
                <input
                  type="checkbox"
                  class="i-checks"
                  style="position: absolute; opacity: 0"
                />
                <ins
                  class="iCheck-helper"
                  style="
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    display: block;
                    width: 100%;
                    height: 100%;
                    margin: 0px;
                    padding: 0px;
                    background: rgb(255, 255, 255);
                    border: 0px;
                    opacity: 0;
                  "
                ></ins>
              </div>
            </td>
            <td class="mail-ontact">
              <a href="mail_detail.html">
                {{ email.from.emailAddress.name }} /
                {{ email.from.emailAddress.address }}
              </a>
            </td>
            <td class="mail-subject">
              <a href="mail_detail.html">{{ email.subject }}</a>
            </td>
            <td class=""><i class="fa fa-paperclip"></i></td>
            <td class="text-right mail-date">{{ email.sentDateTime }}</td>
            <td class="text-right mail-date">
              {{ email.lastModifiedDateTime }}
            </td>
            <td class="text-right mail-date">{{ email.receivedDateTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MailService from "@/services/mail.service.js";

export default {
  name: "Inbox",
  data() {
    return {
      emails: {},
      page: 1,
      top: 20,
      skip: 0,
      parentElementTable: null,
      detalMail: {}
    };
  },
  // computed: {
  //   skip: () => (this.page == 1 ? 0 : this.top * (this.page - 1))
  // },
  created() {
    this.get();
  },
  methods: {
    emailDetal(detal, event) {
      if (this.parentElementTable != null)
        this.parentElementTable.classList.remove("active-table-mail");
      this.parentElementTable = event.path[0].parentElement;
      this.parentElementTable.classList.add("active-table-mail");

      console.log(detal);
      this.detalMail = detal;
      document.getElementById("detal-mail-box").style.width = "73.5%";

      var ifrm = document.getElementById("myIfrm");
      ifrm = ifrm.contentWindow
        ? ifrm.contentWindow
        : ifrm.contentDocument.document
        ? ifrm.contentDocument.document
        : ifrm.contentDocument;
      ifrm.document.open();
      ifrm.document.write(detal.body.content);
      ifrm.document.close();
    },
    closeDetal() {
      if (this.parentElementTable != null)
        this.parentElementTable.classList.remove("active-table-mail");
      document.getElementById("detal-mail-box").style.width = "0";
    },
    nextPage() {
      this.page++;
      this.$forceUpdate();
    },
    prevPage() {
      this.page--;
      this.$forceUpdate();
    },
    get() {
      console.log(this.$route.params.id);
      new MailService()
        .fetchMessagesByUserIdAndFolderId(
          this.$route.params.id,
          this.$route.params.folder,
          this.page,
          this.top,
          this.skip
        )
        .then(resp => {
          console.log(resp);
          this.emails = resp.data;
        })
        .catch(err => {
          this.$toastr.error(err);
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.detal-mail-box {
  position: absolute;
  right: 0;
  background-color: #ffffff;
  width: 0;
  height: 90%;
  border: 1px solid #e7eaec;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.detal-mail-box h1 {
  text-align: center;
}

.detal-mail-box hr {
  width: 90%;
  margin: 0 auto 10px auto;
}

.detal-mail-box .closeIcon {
  position: absolute;
  right: 5%;
  top: 10px;
  cursor: pointer;
}

.detal-mail-box .closeIcon i {
  font-size: 18px;
}

.detal-mail-box .closeIcon i:hover {
  color: #1ab394;
}

.active-table-mail td {
  background-color: #1ab394;
}

.active-table-mail td a,
.active-table-mail td p,
.active-table-mail td,
.active-table-mail i {
  color: #ffffff;
}

.detal-mail-value {
  width: 90%;
  margin-bottom: 25px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  margin: 10px;
  padding: 25px;
}

.detal-mail-subject {
  padding: 15px 25px;
}

.detal-mail-subject h5 {
  font-size: 20px;
  text-transform: uppercase;
}

.detal-mail-to,
.detal-mail-dw,
.detal-mail-from,
.detal-mail-date,
.icon-attachment,
.button-detal-mail {
  width: 90%;
  margin: auto;
}

.detal-mail-date p {
  margin-bottom: 0;
}

.icon-attachment i {
  font-size: 17px;
  margin-left: 10px;
}

.detal-mail-from i,
.detal-mail-date i {
  font-size: 17px;
  margin-right: 10px;
}

.detal-mail-to,
.detal-mail-dw {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.detal-mail-to a,
.detal-mail-dw a {
  margin-right: 5px;
}

.detal-mail-to div,
.detal-mail-dw div {
  margin-left: 5px;
}

.detal-mail-to a:hover,
.detal-mail-dw a:hover,
.detal-mail-from a:hover {
  color: #1ab394;
  text-decoration: underline;
}

.detal-mail-content-row {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.body-box {
  margin: auto;
}

.icon-attachment a:hover i {
  color: #1ab394;
}

.button-detal-mail {
  margin-bottom: 10px;
  text-align: right;
}

.button-detal-mail a {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  width: 100px;
}

.button-detal-mail a:hover {
  background-color: #1ab394;
  color: #ffffff;
}

#myIfrm {
  border: none;
  width: 100%;
  height: 500px;
}
</style>
