<template>
  <Inbox />
</template>

<script>
import Inbox from "@/components/mailbox/Inbox.vue";
export default {
  name: "Mailbox",
  components: {
    Inbox
  }
};
</script>
