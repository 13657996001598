import MainService from "./main.service";

export default class Mail extends MainService {
  constructor() {
    super();
  }

  async fetchAllEmails() {
    try {
      const resp = await this.api_service.get("msal/emails/");
      return resp;
    } catch (err) {
      throw new Error(err.response.status, err.response.data.details);
    }
  }

  async fetchAllFolders(mailbox_id) {
    try {
      const resp = await this.api_service.get(
        `msal/emails/${mailbox_id}/mailFolders`
      );
      return resp;
    } catch (err) {
      throw new Error(err.response.status, err.response.data.details);
    }
  }

  async fetchById(id) {
    try {
      const resp = await this.api_service.get(`msal/emails/${id}`);
      return resp;
    } catch (err) {
      throw new Error(err.response.status, err.response.data.details);
    }
  }

  async fetchMessagesByUserId(id, page, top, skip) {
    try {
      const resp = await this.api_service.get(
        `msal/emails/${id}/messages?$page=${page}&$top=${top}&$skip=${skip}`
      );
      return resp;
    } catch (err) {
      throw new Error(err.response.status, err.response.data.details);
    }
  }

  async fetchMessagesByUserIdAndFolderId(user_id, folder_id, page, top, skip) {
    try {
      const resp = await this.api_service.get(
        `msal/emails/${user_id}/mailFolders/${folder_id}/messages?$page=${page}&$top=${top}&$skip=${skip}`
      );
      return resp;
    } catch (err) {
      throw new Error(err.response.status, err.response.data.details);
    }
  }

  async post(data) {
    try {
      const resp = await this.api_service.post("msal/emails", data);
      return resp;
    } catch (err) {
      throw new Error(err.response.status, err.response.data.details);
    }
  }
}
